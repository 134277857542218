$gr: 1.618;
$gr_major: 0.618;
$gr_minor: 0.382;

$fg: #212121;

$blue: #2196f3;
$fg-err: #f44336;
$pink: #e91e63;

$psblue: #012456;
$link: #0095dd;
